import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const Katalog30 = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(
        relativePath: { eq: "videos/poster-katalog-feiertage-1.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo title="Blanche Kommerell: Wie wenn am Feiertage" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Blanche Kommerell: Wie wenn am Feiertage",
                link: "/k/30",
              },
            ]}
          />
          <PageTitle>Blanche Kommerell: Wie wenn am Feiertage</PageTitle>
          <VideoPlayer
            src="katalog/30-blanche-kommerell"
            poster={data.poster}
          />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog30
